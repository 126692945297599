//Authentication is handled here, please refer to auth.js in utils for request logic

import {
	DATA_LOADED,
	DATA_LOAD_FAILED,
	Action,
	JOB_APP_DATA_UPDATED,
	CONTACT_REQ_DATA_UPDATED,
} from '../utils/consts';

export interface IContactRequest {
	_id: string;
	name: string;
	email: string;
	number: string;
	message: string;
	status: string;
	createdAt: string | null;
}

export interface IJobApplication {
	_id: string;
	name: string;
	email: string;
	number: string;
	preferredLocation: string;
	linkForResume: string;
	status: string;
	createdAt: string | null;
}

export interface IDataInterface {
	contactRequests: IContactRequest[];
	jobApplications: IJobApplication[];
	loading: boolean;
}

const initialState: IDataInterface = {
	contactRequests: [],
	jobApplications: [],
	loading: true,
};

const registerReducer = (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case DATA_LOADED:
			return {
				loading: false,
				...payload,
			};
		case JOB_APP_DATA_UPDATED:
			const jobAppCopy = [...state.jobApplications];
			jobAppCopy[payload.index] = payload.data.updatedJobApplication;
			return {
				...state,
				jobApplications: jobAppCopy,
			};
		case CONTACT_REQ_DATA_UPDATED:
			const contactRequestCopy = [...state.contactRequests];
			contactRequestCopy[payload.index] = payload.data.updatedContactRequest;
			return {
				...state,
				contactRequests: contactRequestCopy,
			};
		case DATA_LOAD_FAILED:
			return {
				contactRequests: [],
				jobApplications: [],
				loading: false,
			};
		default:
			return state;
	}
};

export default registerReducer;
